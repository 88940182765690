@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}
